<template>
  <div class="home">

    <GameView />


  </div>
</template>

<script>
// @ is an alias to /src
import GameView from '@/components/GameView.vue'

export default {
  name: 'HomeView',
  components: {
    GameView
  }
}
</script>
