<template>
<div class="game-container">
    <div class="flag1">
        {{currentFlag.flag}}

    </div>

    <div class="currentName" v-if="false">{{currentFlag.name}}</div>

    <h2>Antwortmöglichkeiten</h2>
    <div class="answers">
        <ul class="answers-list">
            <li :class="item.class" @click="answer(item)" class="answers-list-item" v-for="(item, index) in answers" :key="index">{{item.name}}</li>
        </ul>
    </div>

    <h3>Richtige Antworten:</h3>
    {{correct}}
    <h4>Runden:</h4>
    {{rounds}}

    <div class="reset">
        <button @click="reset">Neu Starten</button>
    </div>
</div>
</template>

<script>
import flagsData from '../assets/data.json'
export default {
    data() {
        return {
            currentFlag: '',
            flags: flagsData,
            answers: [],
            correct: 0,
            rounds:0,

        }
    },
    mounted() {
        this.getRandomFlag();
    },
    computed: {

    },
    methods: {
        reset(){
            this.correct = 0;
            this.rounds = 0;
            this.currentFlag = null;
            this.getRandomFlag();
        },
        answer(item) {
            this.rounds++;
            if (item == this.currentFlag){
                console.log("RICHTIG");
                this.correct++;
            }
            else{
                console.log("FALSCH");
            }

            this.currentFlag.class = "success";

            setTimeout(() => {
                this.currentFlag.class = "";
                this.getRandomFlag();
            }, 1000);
        },
        computeAnswers() {
            this.answers = [];
            this.answers.push(this.currentFlag);
            while(this.answers.length <4){
                let random = this.getRandomItem();
                if(this.answers.indexOf(random) < 0)
                    this.answers.push(random);
            }
            this.shuffle(this.answers);
        },
        getRandomFlag() {
            this.currentFlag = null;
            this.currentFlag = this.getRandomItem()
            this.currentFlag.class = "";
            this.currentFlag.flag = this.getFlagEmoji(this.currentFlag.code);
            this.computeAnswers();
        },
        getRandomItem() {
            let length = this.flags.length;
            let random = Math.floor(Math.random() * length - 1);
            return this.flags[random];

        },
        getFlagEmoji(countryCode) {
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        },
        shuffle(array) {
            let currentIndex = array.length,
                randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex != 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]
                ];
            }

            return array;
        }
    }
}
</script>

<style>
.flag1 {
    font-size: 10em;
}

.answers {
    justify-content: center;
    display: flex;
}

.answers-list {
    display: flex;
    list-style-type: none;
    width: 450px;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;

}

.answers-list-item {
    border: 1px solid green;
    box-sizing: border-box;
    cursor: pointer;
    padding: 1em;
    flex-basis: 50%;
}

.success {
    background-color: mediumaquamarine;
}
.reset{
    margin-top:2em;
}
</style>
